.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex: 1;
}

.left-pane {
  background-color: #fff;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;

  position: relative;
  overflow: hidden;
  padding: 0;
}

h1,
p {
  margin: 0;
  padding: 0;
}

.subtitle {
  font-family: 'Quicksand', sans-serif;
  color: 'black';
  text-transform: uppercase;
}

.right-pane {
  background-color: rgba(78, 78, 78, 0.1);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.content-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.logo {
  width: 50px;
}

.mockup {
  margin-top: 2.5%;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: row;
}

.title>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  background-color: #fff;
  text-align: center;
  padding: 30px 0;
}

.content-form {
  width: 400px;
}

.login-form {
  width: 100%;
}

@media (max-width: 768px) {
  .left-pane {
    display: none;
  }

  .right-pane {
    width: 100%;
  }

  .title {
    text-align: center;
    font-size: 0.8rem;
  }
}

a {
  text-decoration: none;
  margin: 0 5px;
  color: grey;
}

@media (max-width: 868px) {
  .content-form {
    width: 300px;
  }

  .error-modal div {
    max-width: 50% !important;
  }
}


.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  background-color: rgba(217, 217, 217, 0.45);
  padding: 15px;
  /* Ajuster le padding interne pour écarter le placeholder */
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  /* Assurez-vous que le padding est inclus dans la largeur totale */
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #47b8af;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.login-button:hover {
  background-color: #3aa195;
}

.forgot-password {
  text-align: right;
  margin-top: 10px;
}

.forgot-password a {
  color: #47b8af;
  text-decoration: none;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ddd;
}

.register-prompt {
  text-align: center;
}

.register-button {
  width: 100%;
  padding: 10px;
  background: linear-gradient(to right, #fc466b, #8f0734);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.register-button:hover {
  background: linear-gradient(to right, #fc466b, #8f0734);
}

/**  ERROR MODAL **/

.error-modal {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(78, 78, 78, 0.7);
}

.error-modal div {
  max-width: 30%;
  background-color: #ddd;
  padding: 15px 25px;
  border-radius: 15px;
}

.error-modal h1,
h2 {
  font-weight: 350;
  margin: 0;
  padding: 0;
}

.error-modal h1 {
  font-size: 1.4rem;
}

.error-modal h2 {
  font-size: 1rem;
}